<template>
    <main>
		<HeaderTab 
            :title="$t('global.partants')"
        />

        <div class="container-fluid">
            <div id="content">
                <Perfs />
            </div>
        </div>
	</main>
</template>


<script type="text/javascript">
	export default {
		name: "partants",
		components: {
            HeaderTab: () => import('GroomyRoot/components/HeaderTab'),
			Perfs: () => import('@/components/Dedicated/Acts/Perfs')
		}
	}
</script>
